import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { CartContext } from 'contexts/CartContext'
import { PRODUCT } from 'constants/product'
import {
  MinusCircleIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import ProductImage from 'components/product/product-image'
import ProductName from 'components/product/product-name'
import ProductSupplier from 'components/product/product-supplier'
import ProductAuthors from 'components/product/product-authors'
import ProductPrice from 'components/product/product-price'
import BtnAddToWishList from 'components/buttons/btn-add-to-wishlist'

export default function CartItem({ product }) {
  const { increase, decrease, removeProduct } = useContext(CartContext)

  return (
    <div className="flex flex-1 flex-row space-x-4 items-stretch">
      <div className="basis-24 flex flex-col items-stretch">
        <Link to={product.url} title={product.name} className="hover:underline">
          <ProductImage
            type={PRODUCT.TYPE.BOOK}
            productName={product.name}
            productUrl={product.url}
            productImage={product.images}
            productStock={product.stock}
            productDiscountPerc={product.discount_percentage}
          />
        </Link>
      </div>

      <div className="flex flex-1 items-stretch">
        <div className="basis-5/6">
          <h2 className="text-xl font-bold">
            <Link
              to={product.url}
              title={product.name}
              className="hover:underline"
            >
              <ProductName productName={product.name} />
            </Link>
          </h2>
          <h3 className="text-sky-600 mt-1">
            <ProductAuthors authors={product.authors} />
          </h3>
          <h3 className="text-gray-400 mt-1">
            Publisher:{' '}
            <ProductSupplier
              supplierName={product.supplier.name}
              supplierUrl={product.supplier.url}
            />
          </h3>
          <div className="float-left mt-1">
            <BtnAddToWishList />
          </div>
        </div>
        <div className="basis-1/6">
          <div className="text-xl mb-1">
            <ProductPrice
              productPrice={product.price}
              productDiscountPerc={product.discount_percentage}
              productDiscountPrice={product.discount_price}
              size="medium"
            />
          </div>

          <div className="flex mt-auto">
            <div className="grow">Qty: {product.quantity}</div>
            <div className="flex">
              <button onClick={() => increase(product)} className="mx-1">
                <PlusCircleIcon className="w-5 h-5" />
              </button>

              {product.quantity > 1 && (
                <button onClick={() => decrease(product)} className="mx-1">
                  <MinusCircleIcon className="w-5 h-5" />
                </button>
              )}

              {product.quantity === 1 && (
                <button onClick={() => removeProduct(product)} className="mx-1">
                  <TrashIcon className="w-5 h-5" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
