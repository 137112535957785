import MyIconStar from 'components/icons/star'

export default function ReviewStarsCategory({
  noOfReviews,
  rate,
  rateAverage,
}) {
  let bgStyle
  switch (rate) {
    case 1:
      bgStyle = `bg-red-500`
      break
    case 2:
      bgStyle = `bg-orange-500`
      break
    case 3:
      bgStyle = `bg-yellow-500`
      break
    case 4:
      bgStyle = `bg-green-500`
      break
    case 5:
      bgStyle = `bg-green-700`
      break
    default:
      bgStyle = ``
      break
  }
  return (
    <div className="flex cursor-pointer hover:bg-gray-300 rounded-full p-1 group">
      <div className="flex-none px-1 py-0.5 w-5">{rate}</div>
      <div className="flex-none w-5">
        <MyIconStar size="small" color="#222222" />
      </div>
      <div className="grow px-1 py-2">
        <div className="overflow-hidden rounded-full bg-gray-300">
          <div
            className={`h-2 rounded-full ${bgStyle}`}
            style={{ width: rateAverage }}
          />
        </div>
      </div>
      <div className="flex-none  px-1 py-0.5 w-24">
        {noOfReviews} {noOfReviews === 1 ? `review` : `reviews`}
      </div>
    </div>
  )
}
