import React from 'react'
import { useUtils } from 'hooks/utils'

export default function ProductPrice({
  productPrice,
  productDiscountPerc,
  productDiscountPrice,
  size = 'small',
}) {
  let { formatNumber } = useUtils()
  return (
    <>
      {productDiscountPerc > 0 && (
        <>
          <p
            className={`${size === `small` && `text-md font-bold`} ${
              size === `medium` && `text-xl font-bold`
            } ${
              size === `large` && `font-merriweather font-extrabold text-3xl`
            } leading-none`}
          >
            {formatNumber(productDiscountPrice)}
          </p>
          <p
            className={`${size === `small` && `text-sm`} ${
              size === `medium` && `text-sm`
            } ${size === `large` && `text-md`} text-gray-400 line-through`}
          >
            {formatNumber(productPrice)}
          </p>
        </>
      )}
      {productDiscountPerc === 0 && (
        <span
          className={`${size === `small` && `text-md font-bold`} ${
            size === `medium` && `text-xl font-bold`
          } ${
            size === `large` && `font-merriweather font-extrabold text-3xl`
          } leading-none`}
        >
          {formatNumber(productPrice)}
        </span>
      )}
    </>
  )
}
