import { APP_NAME } from 'config'
import MyIconTwitter from 'components/icons/twitter'
import AmericanExpress from 'assets/images/icons/AmericanExpress.svg'
import Mastercard from 'assets/images/icons/Mastercard.svg'
import Ozow from 'assets/images/icons/Ozow.svg'
import Payflex from 'assets/images/icons/Payflex.svg'
import Visa from 'assets/images/icons/Visa.svg'

export default function Footer() {
  const date = new Date()
  return (
    <>
      <section className="relative py-7 bg-gray-300 border-t border-gray-300">
        <div className="mx-auto px-8 max-w-7xl">
          <div className="flex w-full items-stretch">
            <div className="basis-1/4">
              <h4 className="font-merriweather font-semibold text-lg">
                Customer Services
              </h4>
              <ul className="text-sm">
                <li className="py-0.5">
                  <a href="#." className="hover:cursor-pointer hover:underline">
                    Contact Us
                  </a>
                </li>
                <li className="py-0.5">
                  <a href="#." className="hover:cursor-pointer hover:underline">
                    FAQs
                  </a>
                </li>
                <li className="py-0.5">
                  <a href="#." className="hover:cursor-pointer hover:underline">
                    Delivery Information
                  </a>
                </li>
                <li className="py-0.5">
                  <a href="#." className="hover:cursor-pointer hover:underline">
                    Guarantee & Returns
                  </a>
                </li>
                <li className="py-0.5">
                  <a href="#." className="hover:cursor-pointer hover:underline">
                    Suggest a Book
                  </a>
                </li>
                <li className="py-0.5">
                  <a href="#." className="hover:cursor-pointer hover:underline">
                    Suggest an Author/Publisher
                  </a>
                </li>
              </ul>
            </div>
            <div className="basis-1/4">
              <h4 className="font-merriweather font-semibold text-lg mb-2">
                Shop
              </h4>
              <ul className="text-sm">
                <li className="py-0.5">
                  <a href="#." className="hover:cursor-pointer hover:underline">
                    Best Sellers
                  </a>
                </li>
                <li className="py-0.5">
                  <a href="#." className="hover:cursor-pointer hover:underline">
                    New Arrivals
                  </a>
                </li>
                <li className="py-0.5">
                  <a href="#." className="hover:cursor-pointer hover:underline">
                    Gifts & Accessories
                  </a>
                </li>
                <li className="py-0.5">
                  <a href="#." className="hover:cursor-pointer hover:underline">
                    Award Winning
                  </a>
                </li>
              </ul>
            </div>
            <div className="basis-1/4">
              <h4 className="font-merriweather font-semibold text-lg">
                Account
              </h4>
              <ul className="text-sm">
                <li className="py-0.5">
                  <a href="#." className="hover:cursor-pointer hover:underline">
                    Login
                  </a>
                </li>
                <li className="py-0.5">
                  <a href="#." className="hover:cursor-pointer hover:underline">
                    Register
                  </a>
                </li>
                <li className="py-0.5">
                  <a href="#." className="hover:cursor-pointer hover:underline">
                    Track Order
                  </a>
                </li>
                <li className="py-0.5">
                  <a href="#." className="hover:cursor-pointer hover:underline">
                    Guarantee & Returns
                  </a>
                </li>
              </ul>
            </div>

            <div className="basis-1/4">
              <h4 className="font-merriweather font-semibold text-lg">
                Company
              </h4>
              <ul className="text-sm">
                <li className="py-0.5">
                  <a href="#." className="hover:cursor-pointer hover:underline">
                    About Us
                  </a>
                </li>
                <li className="py-0.5">
                  <a href="#." className="hover:cursor-pointer hover:underline">
                    Sell on {APP_NAME}
                  </a>
                </li>
                <li className="py-0.5">
                  <a href="#." className="hover:cursor-pointer hover:underline">
                    Cookie Policy
                  </a>
                </li>
                <li className="py-0.5">
                  <a href="#." className="hover:cursor-pointer hover:underline">
                    Terms & Conditions
                  </a>
                </li>
                <li className="py-0.5">
                  <a href="#." className="hover:cursor-pointer hover:underline">
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="relative py-4 bg-gray-700 text-gray-200">
        <div className="mx-auto px-8 max-w-7xl">
          <div className="flex w-full items-stretch text-sm">
            <div className="w-4/6 flex items-center space-x-3">
              <p>
                &copy; {date.getFullYear()} {APP_NAME}. All Rights Reserved.
              </p>
              <img alt="Visa" src={Visa} className="w-auto h-4" />
              <img alt="Mastercard" src={Mastercard} className=" w-auto h-6" />
              <img
                alt="AmericanExpress"
                src={AmericanExpress}
                className=" w-auto h-5"
              />
              <img alt="Ozow" src={Ozow} className="w-auto h-5" />
              <img alt="Payflex" src={Payflex} className=" w-auto h-4" />
            </div>
            <div className="w-2/6 flex justify-end space-x-1">
              <a href="#." className="hover:cursor-pointer hover:underline">
                Help
              </a>
              <span>|</span>
              <a
                href="#."
                className="hover:cursor-pointer hover:underline flex"
              >
                <span>Find us on Twitter</span>
                <MyIconTwitter />
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
