import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PORTAL } from 'routes/portal'
import { ShoppingCartIcon } from '@heroicons/react/24/outline'

export default function CartEmpty({ isSideBar, setOpen = {} }) {
  const navigate = useNavigate()
  return (
    <div className="text-center">
      <div
        className={`m-auto rounded-full bg-gray-100 p-5 ${
          isSideBar ? `p-5 h-20 w-20` : `p-7 h-36 w-36`
        }`}
      >
        <ShoppingCartIcon
          className={`inline-block ${isSideBar ? `h-10 w-10` : `h-20 w-20`}`}
        />
      </div>

      <div className="text-lg my-5">Your shopping cart is empty.</div>

      <button
        type="button"
        className="inline-block cursor-pointer bg-sky-600 hover:bg-sky-700 text-white text-center text-sm py-2 px-3 mx-auto font-medium"
        onClick={() => {
          isSideBar ? setOpen(false) : navigate(PORTAL.HOME)
        }}
      >
        Continue Shopping
      </button>
    </div>
  )
}
