import { useState, useEffect } from 'react'
import { ApiConsumer } from 'middleware/api-consumer'
import { GLOBAL } from 'constants/global'
import ProductCard from 'components/product/product-card'
import CarouselSlider from 'components/common/carousel-slider'
import { useProduct } from 'hooks/product'

export default function WideSliderCard({
  type,
  sliderSettings = {},
  max = null,
  showBtn = false,
  showCard = true,
}) {
  let { getProduct } = useProduct()
  const [dataProducts, setDataProducts] = useState([])

  useEffect(() => {
    ApiConsumer.get(type.ROUTE)
      .then((res) => {
        const dataProducts = res.data.map((dataProduct) => {
          return getProduct(dataProduct)
        })
        setDataProducts(
          max !== null ? dataProducts.slice(0, max) : dataProducts,
        )
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {})
  }, [type, max])

  const sliderContent = dataProducts.map((product, i) => (
    <div
      key={product.id}
      className="h-full relative basis-2/12 hover:cursor-pointer group"
    >
      {type === GLOBAL.SLIDER.TOP10 && (
        <div className="absolute top-8 left-0 z-10 bg-yellow-500 rounded-tr rounded-br px-2 py-1 font-merriweather font-extrabold text-[18px]">
          {i + 1}
        </div>
      )}
      {type === GLOBAL.SLIDER.AWARD_WINNING && product.award !== undefined && (
        <div className="absolute top-8 left-0 z-10 bg-yellow-500 rounded-tr rounded-br px-2 py-1 font-merriweather font-extrabold text-[14px]">
          {product.award}
        </div>
      )}
      <ProductCard product={product} />
    </div>
  ))

  return (
    <>
      {showCard && (
        <div className="w-4/12 p-0.5 border border-gray-200 shadow">
          <div
            className={`flex items-center justify-center relative text-center p-4 ${type.BG_LIGHT} w-full h-1/2 font-merriweather font-extrabold text-4xl`}
          >
            <div className="z-20">{type.TITLE}</div>
            <div
              className={`chevron absolute z-10 ${type.BG_DARK} top-0 left-0 h-full w-3/5`}
            ></div>
          </div>
          <div className="flex items-center justify-center text-center p-4 bg-gray-100 w-full h-1/2 font-merriweather font-base text-md">
            <div>
              <div className="w-full mb-2 italic">
                {type.QUOTES[Math.floor(Math.random() * type.QUOTES.length)]}
              </div>
              {showBtn && (
                <span className="inline-block font-merriweather hover:cursor-pointer bg-amber-200 px-3 py-2">
                  Suggest "{type.TITLE}" Book
                </span>
              )}
            </div>
          </div>
        </div>
      )}

      <div className={`${showCard ? `w-8/12` : `w-full`} wide-slider`}>
        <CarouselSlider
          sliderContent={sliderContent}
          sliderSettings={sliderSettings}
        />
      </div>
    </>
  )
}
