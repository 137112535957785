export const useProduct = () => {
  function getProductPrice(productPrice) {
    productPrice = new Intl.NumberFormat('en-GB', {
      minimumFractionDigits: 2,
    }).format(productPrice)

    return productPrice
  }

  function getProductSalePrice(productPrice, productDiscountPerc) {
    let productDiscountPrice =
      productDiscountPerc > 0
        ? productPrice - productPrice * (productDiscountPerc / 100)
        : 0

    productDiscountPrice = new Intl.NumberFormat('en-GB', {
      minimumFractionDigits: 2,
    }).format(productDiscountPrice)

    return productDiscountPrice
  }

  function getProduct(dataProduct) {
    dataProduct.price = getProductPrice(dataProduct.price)
    dataProduct.discount_price = getProductSalePrice(
      dataProduct.price,
      dataProduct.discount_percentage,
    )
    if (!dataProduct.shortDesc || dataProduct.shortDesc === '')
      dataProduct.shortDesc =
        dataProduct.description.replace(/(<([^>]+)>)/gi, '').substr(0, 200) +
        ` ... `
    return dataProduct
  }

  return {
    getProduct,
    getProductPrice,
    getProductSalePrice,
  }
}
