import { PencilIcon } from '@heroicons/react/24/solid'

export default function BtnWriteReview() {
  return (
    <button
      type="button"
      className="font-merriweather rounded-sm hover:cursor-pointer bg-sky-600 px-3 py-2 text-white inline-block text-center text-sm p-2 my-1 font-medium whitespace-nowrap"
    >
      <PencilIcon
        className="text-white -mt-0.5 h-5 w-5 inline mr-1"
        aria-hidden="true"
      />{' '}
      Write a Review
    </button>
  )
}
