import { GLOBAL } from 'constants/global'

export const PORTAL = {
  NOT_FOUND: `/404`,
  HOME: `/`,
  LOGIN: `/login`,
  FORGOTPASSWORD: `/forgot-password`,
  SEARCH: `/search`,
  PRODUCT_PAGE: `/:product_slug/${GLOBAL.URLS.PRODUCT_PREFIX}:product_id`,
  SUPPLIER_PAGE: `/:supplier_slug/${GLOBAL.URLS.SUPPLIER_PREFIX}:supplier_id`,
  CART: `/cart`,
}
