import React from 'react'
import { Link } from 'react-router-dom'

export default function ProductAuthors({ authors }) {
  return authors.map((item, index) => (
    <React.Fragment key={item.id}>
      <Link className="hover:underline" to={item.url}>
        {item.name}
      </Link>
      {authors.length > 1 && index !== authors.length - 1 && <>,&nbsp;</>}
    </React.Fragment>
  ))
}
