import { Fragment, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { PORTAL } from 'routes/portal'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import CartEmpty from 'components/cart/cart-empty'
import { CartContext } from 'contexts/CartContext'
import CartItemSideBar from 'components/cart/cart-item-side-bar'
import { useUtils } from 'hooks/utils'
import BtnPrimary from 'components/buttons/btn-primary'

export default function SideBar({ open, setOpen }) {
  const navigate = useNavigate()
  const { cartItems, itemCount, total } = useContext(CartContext)
  let { formatNumber } = useUtils()

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900 bg-opacity-50 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="bg-white p-4 border-b border-gray-200">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium ">
                            Shopping Cart ({itemCount})
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              className="outline-none"
                              type="button"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6 text-gray-400"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative p-4">
                        {cartItems.length ? (
                          <>
                            {cartItems.map((product) => (
                              <div className="w-full" key={product.id}>
                                <CartItemSideBar
                                  product={product}
                                  setOpen={setOpen}
                                />
                              </div>
                            ))}
                            <div className="w-full text-md font-bold text-right border-b border-gray-200 mb-3 pb-3">
                              Total: {formatNumber(total)}
                            </div>

                            <div className="w-full text-center my-5">
                              <BtnPrimary
                                text={`Proceed to Checkout`}
                                background="bg-amber-200"
                                icon={
                                  <ChevronRightIcon
                                    className={`-ml-1 -mt-0.5 h-5 w-5 inline`}
                                    aria-hidden="true"
                                  />
                                }
                              />
                            </div>

                            <div
                              className="w-full text-center my-2"
                              onClick={() => setOpen(false)}
                            >
                              <span className="hover:cursor-pointer hover:underline">
                                Continue Shopping
                              </span>
                            </div>
                            <div className="w-full text-center">
                              <span
                                className="hover:cursor-pointer hover:underline"
                                onClick={() => {
                                  navigate(PORTAL.CART)
                                  setOpen(false)
                                }}
                              >
                                View Cart
                              </span>
                            </div>
                            <div className="w-full text-sm text-gray-500 my-3">
                              <em>
                                Placing an item in your shopping cart does not
                                reserve that item or price. We only reserve
                                stock for your order once payment is received.
                              </em>
                            </div>
                          </>
                        ) : (
                          <CartEmpty isSideBar={true} setOpen={setOpen} />
                        )}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}
