import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XCircleIcon } from '@heroicons/react/24/outline'
import { AppLoader } from 'components/loader/app-loader'

export default function Modal({
  modalHeaderTag = '',
  modalHeaderIcons = [],
  modalHeader,
  modalContent,
  open,
  setOpen,
}) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-50 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
              <AppLoader
                loaderContent={
                  <div>
                    <div className="text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-xl leading-6 font-medium text-gray-900 flex"
                      >
                        <div className="flex-1">
                          {modalHeaderTag && (
                            <span className="header-tag-green mr-2">
                              {modalHeaderTag}
                            </span>
                          )}
                          <strong>
                            <span
                              dangerouslySetInnerHTML={{ __html: modalHeader }}
                            />
                          </strong>
                          {modalHeaderIcons.map((button, i) => (
                            <Fragment key={i}>{button}</Fragment>
                          ))}
                        </div>
                        <button
                          type="button"
                          className="icon-transparent m-0"
                          onClick={() => setOpen(false)}
                        >
                          <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </Dialog.Title>
                      <hr className="my-4" />
                      {modalContent}
                    </div>
                  </div>
                }
              />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
