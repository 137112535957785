import { useState, useContext } from 'react'
import { ShoppingCartIcon } from '@heroicons/react/24/solid'
import { CartContext } from 'contexts/CartContext'
import SideBar from 'components/common/side-bar'

export default function BtnCartIcon({ showText = false }) {
  const [open, setOpen] = useState(false)
  const { itemCount } = useContext(CartContext)

  return (
    <>
      <SideBar open={open} setOpen={setOpen} />
      <button
        type="button"
        className="group space-x-1 flex items-center text-sm font-medium text-gray-600 hover:text-gray-900 whitespace-nowrap"
        onClick={() => setOpen(true)}
      >
        <ShoppingCartIcon
          className="h-8 w-8 flex-shrink-0 bg-sky-600 text-white rounded-full p-1.5"
          aria-hidden="true"
        />
        <span>
          {showText && `Cart `} ({itemCount})
        </span>
        <span className="sr-only">items in cart, view bag</span>
      </button>
    </>
  )
}
