import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { CartContext } from 'contexts/CartContext'
import { PRODUCT } from 'constants/product'
import {
  MinusCircleIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import ProductImage from 'components/product/product-image'
import ProductName from 'components/product/product-name'
import ProductPrice from 'components/product/product-price'

export default function CartItemSideBar({ product, setOpen }) {
  const { increase, decrease, removeProduct } = useContext(CartContext)

  return (
    <div className="flex flex-1 flex-row space-x-2 pb-3 mb-3 items-stretch border-b border-gray-200">
      <div className="basis-1/4 flex flex-col items-stretch">
        <Link
          to={product.url}
          title={product.name}
          className="hover:underline"
          onClick={() => setOpen(false)}
        >
          <ProductImage
            type={PRODUCT.TYPE.BOOK}
            productName={product.name}
            productUrl={product.url}
            productImage={product.images}
            productStock={product.stock}
            productDiscountPerc={product.discount_percentage}
          />
        </Link>
      </div>

      <div className="basis-3/4 flex flex-1 flex-col items-stretch">
        <h3 className="leading-tight line-clamp-2 mb-1 text-sky-600">
          <Link
            to={product.url}
            title={product.name}
            className="hover:underline"
            onClick={() => setOpen(false)}
          >
            <ProductName productName={product.name} />
          </Link>
        </h3>

        <div className="leading-none block">
          <ProductPrice
            productPrice={product.price}
            productDiscountPerc={product.discount_percentage}
            productDiscountPrice={product.discount_price}
            size="small"
          />
        </div>

        <div className="flex mt-auto">
          <div className="grow">Qty: {product.quantity}</div>
          <div className="flex">
            <button onClick={() => increase(product)} className="mx-1">
              <PlusCircleIcon className="w-5 h-5" />
            </button>

            {product.quantity > 1 && (
              <button onClick={() => decrease(product)} className="mx-1">
                <MinusCircleIcon className="w-5 h-5" />
              </button>
            )}

            {product.quantity === 1 && (
              <button onClick={() => removeProduct(product)} className="mx-1">
                <TrashIcon className="w-5 h-5" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
