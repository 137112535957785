import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { APP_NAME, APP_LOGO } from "config";
import {
    Bars3Icon,
    ChartBarIcon,
    CursorArrowRaysIcon,
    ShieldCheckIcon,
    Squares2X2Icon,
    XMarkIcon,
} from "@heroicons/react/24/outline";

import BtnCartIcon from "components/buttons/btn-cart-icon";
import BtnWishlistIcon from "components/buttons/btn-wishlist-icon";

export default function Header() {
    const solutions = [
        {
            name: "Analytics",
            description:
                "Get a better understanding of where your traffic is coming from.",
            href: "#.",
            icon: ChartBarIcon,
        },
        {
            name: "Engagement",
            description:
                "Speak directly to your customers in a more meaningful way.",
            href: "#.",
            icon: CursorArrowRaysIcon,
        },
        {
            name: "Security",
            description: "Your customers' data will be safe and secure.",
            href: "#.",
            icon: ShieldCheckIcon,
        },
        {
            name: "Integrations",
            description:
                "Connect with third-party tools that you're already using.",
            href: "#.",
            icon: Squares2X2Icon,
        },
    ];

    return (
        <>
            <Popover className="relative bg-white">
                <div
                    className="pointer-events-none absolute inset-0 z-30 border-b border-gray-200"
                    aria-hidden="true"
                />
                <div className="relative z-20">
                    <div className="mx-auto flex max-w-7xl items-center justify-between px-4 py-5 sm:px-6 sm:py-4 md:justify-start md:space-x-10 lg:px-8">
                        <div>
                            <a href="/" className="flex" title={APP_NAME}>
                                <span className="sr-only">{APP_NAME}</span>
                                <img
                                    className="h-10 w-auto"
                                    src={APP_LOGO}
                                    alt={APP_NAME}
                                />
                            </a>
                        </div>
                        <div className="-my-2 -mr-2 md:hidden">
                            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                <span className="sr-only">Open menu</span>
                                <Bars3Icon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                />
                            </Popover.Button>
                        </div>
                        <div className="hidden md:flex md:flex-1 md:items-center md:justify-between">
                            <Popover.Group as="nav" className="flex space-x-4">
                                <a
                                    href="#."
                                    className="text-sm font-medium text-gray-600 hover:text-gray-900 inline-flex items-center"
                                >
                                    Help
                                </a>
                                <a
                                    href="#."
                                    className="text-sm font-medium text-gray-600 hover:text-gray-900 inline-flex items-center"
                                >
                                    Book Clubs
                                </a>

                                <a
                                    href="#."
                                    className="text-sm font-medium text-gray-600 hover:text-gray-900 inline-flex items-center"
                                >
                                    Publishers
                                </a>
                                <a
                                    href="#."
                                    className="text-sm font-medium text-gray-600 hover:text-gray-900 inline-flex items-center"
                                >
                                    Accessories
                                </a>
                                <a
                                    href="#."
                                    className="text-sm font-medium text-gray-600 hover:text-gray-900 inline-flex items-center"
                                >
                                    New
                                </a>
                            </Popover.Group>
                            <div className="flex items-center">
                                <div className="flex space-x-4">
                                    <div className="flex">
                                        <a
                                            href="#."
                                            className="text-sm font-medium text-gray-600 hover:text-gray-900"
                                        >
                                            Login
                                        </a>
                                    </div>

                                    <div className="flex">
                                        <a
                                            href="#."
                                            className="text-sm font-medium text-gray-600 hover:text-gray-900"
                                        >
                                            Register
                                        </a>
                                    </div>
                                    <div className="flex">
                                        <a
                                            href="#."
                                            className="text-sm font-medium text-gray-600 hover:text-gray-900"
                                        >
                                            My Orders
                                        </a>
                                    </div>
                                </div>

                                <span
                                    className="mx-3 h-6 w-px bg-gray-300"
                                    aria-hidden="true"
                                />

                                <div className="flex items-center">
                                    <div className="flex space-x-4">
                                        <div className="flex">
                                            <BtnWishlistIcon showText={true} />
                                        </div>

                                        <div className="flex">
                                            <BtnCartIcon showText={true} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Transition
                    as={Fragment}
                    enter="duration-200 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Popover.Panel
                        focus
                        className="absolute inset-x-0 top-0 z-30 origin-top-right transform p-2 transition md:hidden"
                    >
                        <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="px-5 pt-5 pb-6 sm:pb-8">
                                <div className="flex items-center justify-between">
                                    <div>
                                        <img
                                            className="h-8 w-auto"
                                            src={APP_LOGO}
                                            alt={APP_NAME}
                                        />
                                    </div>
                                    <div className="-mr-2">
                                        <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                            <span className="sr-only">
                                                Close menu
                                            </span>
                                            <XMarkIcon
                                                className="h-6 w-6"
                                                aria-hidden="true"
                                            />
                                        </Popover.Button>
                                    </div>
                                </div>
                                <div className="mt-6 sm:mt-8">
                                    <nav>
                                        <div className="grid gap-7 sm:grid-cols-2 sm:gap-y-8 sm:gap-x-4">
                                            {solutions.map((item) => (
                                                <a
                                                    key={item.name}
                                                    href={item.href}
                                                    className="-m-3 flex items-center rounded-lg p-3 hover:bg-gray-100"
                                                >
                                                    <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                                                        <item.icon
                                                            className="h-6 w-6"
                                                            aria-hidden="true"
                                                        />
                                                    </div>
                                                    <div className="ml-4 text-base font-medium text-gray-900">
                                                        {item.name}
                                                    </div>
                                                </a>
                                            ))}
                                        </div>
                                        <div className="mt-8 text-base">
                                            <a
                                                href="#."
                                                className="font-medium text-indigo-600 hover:text-indigo-500"
                                            >
                                                View all products
                                                <span aria-hidden="true">
                                                    {" "}
                                                    &rarr;
                                                </span>
                                            </a>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                            <div className="py-6 px-5">
                                <div className="grid grid-cols-2 gap-4">
                                    <a
                                        href="#."
                                        className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700"
                                    >
                                        Pricing
                                    </a>
                                    <a
                                        href="#."
                                        className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700"
                                    >
                                        Docs
                                    </a>
                                    <a
                                        href="#."
                                        className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700"
                                    >
                                        Company
                                    </a>
                                    <a
                                        href="#."
                                        className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700"
                                    >
                                        Resources
                                    </a>
                                    <a
                                        href="#."
                                        className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700"
                                    >
                                        Blog
                                    </a>
                                    <a
                                        href="#."
                                        className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700"
                                    >
                                        Contact Sales
                                    </a>
                                </div>
                                <div className="mt-6">
                                    <a
                                        href="#."
                                        className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                                    >
                                        Sign up
                                    </a>
                                    <p className="mt-6 text-center text-base font-medium text-gray-600">
                                        Existing customer?{" "}
                                        <a
                                            href="#."
                                            className="text-indigo-600 hover:text-indigo-500"
                                        >
                                            Sign in
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Popover.Panel>
                </Transition>
            </Popover>
        </>
    );
}
