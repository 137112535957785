export const PRODUCT = {
  TYPE: {
    BOOK: {
      NAME: 'Book',
      IMAGES: 'book-covers',
    },
    ACCESSORY: {
      NAME: 'Accessory',
      IMAGES: 'accessories',
    },
  },
  WISHLIST: 'Add to Wishlist',
}
