import { useContext, useState } from 'react'
import {
  ShoppingCartIcon,
  BellAlertIcon,
  CheckIcon,
} from '@heroicons/react/24/solid'
import { PlusIcon } from '@heroicons/react/24/outline'
import { CartContext } from 'contexts/CartContext'
import MyIconLoaderWhite from 'components/icons/loader-white'
import ModalNotifyWhenAvailable from 'components/modals/modal-notify-when-available'
import Modal from 'components/modals/modal'

export default function BtnAddToCart({ product, showText = true }) {
  const [loadingToCart, setLoadingToCart] = useState(false)
  const [cartLoaded, setCartLoaded] = useState(false)
  const { cartItems, addProduct, increase } = useContext(CartContext)
  const [openModalNotify, setOpenModalNotify] = useState(false)

  const isInCart = (product) => {
    return cartItems.filter((item) => item.id === product.id)
  }

  const loadToCart = () => {
    setLoadingToCart(true)
    setTimeout(function () {
      isInCart(product).length ? increase(product) : addProduct(product)
      setLoadingToCart(false)
      setCartLoaded(true)
      setTimeout(function () {
        setCartLoaded(false)
      }, 3000)
    }, 3000)
  }

  return (
    <>
      <Modal
        modalHeaderTag={''}
        modalHeader={`Notify Me When Available`}
        modalContent={<ModalNotifyWhenAvailable />}
        open={openModalNotify}
        setOpen={setOpenModalNotify}
      />
      {product.stock !== 0 ? (
        <button
          type="button"
          className={`cursor-pointer w-full rounded-sm bg-sky-600 hover:bg-sky-700 text-white text-center text-sm p-2 my-1 font-medium whitespace-nowrap`}
          onClick={() => !loadingToCart && loadToCart()}
        >
          {loadingToCart ? (
            <MyIconLoaderWhite />
          ) : cartLoaded ? (
            <>
              <CheckIcon
                className={`h-4 w-4 flex-shrink-0 inline-block -mt-1`}
              />
              <ShoppingCartIcon
                className={`h-4 w-4 flex-shrink-0 inline-block -mt-1`}
              />
              {showText && ' Added to Cart'}
            </>
          ) : (
            <>
              <PlusIcon
                className={`h-4 w-4 flex-shrink-0 inline-block -mt-1`}
                aria-hidden="true"
              />
              <ShoppingCartIcon
                className={`h-4 w-4 flex-shrink-0 inline-block -mt-1`}
                aria-hidden="true"
              />
              {showText && ' Add to Cart'}
            </>
          )}
        </button>
      ) : (
        <button
          type="button"
          className={`cursor-pointer w-full rounded-sm bg-sky-600 hover:bg-sky-700 text-white text-center text-sm p-2 my-1 font-medium whitespace-nowrap`}
          onClick={() => {
            setOpenModalNotify(true)
          }}
        >
          <BellAlertIcon
            className="h-4 w-4 flex-shrink-0 inline-block -mt-1"
            aria-hidden="true"
          />
          {showText && ' Notify Me When Available'}
        </button>
      )}
    </>
  )
}
