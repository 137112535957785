import React from 'react'
import { Link } from 'react-router-dom'
import { PRODUCT } from 'constants/product'
import ProductImage from 'components/product/product-image'
import ProductName from 'components/product/product-name'
import ProductAuthors from 'components/product/product-authors'
import ProductPrice from 'components/product/product-price'
import BtnAddToWishList from 'components/buttons/btn-add-to-wishlist'
import BtnAddToCart from 'components/buttons/btn-add-to-cart'
import MyIconStar from 'components/icons/star'

export default function LatestItem({ product }) {
  return (
    <div className="flex flex-1 flex-row space-x-4 items-stretch">
      <div className="flex flex-col">
        <Link to={product.url} title={product.name} className="hover:underline">
          <ProductImage
            type={PRODUCT.TYPE.BOOK}
            productName={product.name}
            productUrl={product.url}
            productImage={product.images}
            productStock={product.stock}
            productDiscountPerc={product.discount_percentage}
          />
        </Link>
      </div>

      <div className="flex flex-col">
        <div className="basis-5/6">
          <h2 className="text-xl font-bold">
            <Link
              to={product.url}
              title={product.name}
              className="hover:underline"
            >
              <ProductName productName={product.name} />
            </Link>
          </h2>
          <h3 className="text-sky-600 mt-1">
            <ProductAuthors authors={product.authors} />
          </h3>

          <p className="text-gray-400 mt-1 text-sm">{product.shortDesc}</p>

          <div className="float-left mt-1 mr-2">
            <BtnAddToCart product={product} showText={true} />
          </div>
          <div className="float-left mt-1">
            <BtnAddToWishList />
          </div>
        </div>
      </div>

      <div className="flex flex-col">
        <div className="text-xl mb-1">
          <ProductPrice
            productPrice={product.price}
            productDiscountPerc={product.discount_percentage}
            productDiscountPrice={product.discount_price}
            size="medium"
          />
          <span className="leading-none mr-3 text-sm">
            <MyIconStar />
            <span className="ml-0.5 text-sm text-gray-400">
              {product.rating}
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}
