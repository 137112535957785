import { useContext, useEffect } from 'react'
import { AppLoaderContext } from 'contexts/loader'
import MyIconLoader from 'components/icons/loader'

export const AppLoader = ({ loaderContent = <></>, sectionLoading = null }) => {
  let { loading, setLoading } = useContext(AppLoaderContext)

  useEffect(() => {
    if (sectionLoading !== null) setLoading(sectionLoading)
  }, [sectionLoading])

  return (
    <>
      {loaderContent}
      {loading && (
        <>
          <div className="absolute top-0 left-0 h-full w-full z-50"></div>
          <div className="absolute top-0 left-0 h-full w-full z-50 flex justify-center items-center text-center">
            <MyIconLoader />
          </div>
        </>
      )}
    </>
  )
}
