import React, { useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { PRODUCT } from 'constants/product'
import ProductImage from 'components/product/product-image'
import ProductName from 'components/product/product-name'
import ProductAuthors from 'components/product/product-authors'
import ProductPrice from 'components/product/product-price'
import MyIconStar from 'components/icons/star'
import BtnAddToCart from 'components/buttons/btn-add-to-cart'
import BtnAddToWishList from 'components/buttons/btn-add-to-wishlist'

export default function ProductCard({ product }) {
  const HoverHelper = () => {
    const [isHovered, setHovered] = useState()
    const eventHandlers = useMemo(
      () => ({
        onMouseEnter() {
          setHovered(true)
        },
        onMouseLeave() {
          setHovered(false)
        },
      }),
      [],
    )

    return [isHovered, eventHandlers]
  }

  const [isCardHovered, cardEventHandlers] = HoverHelper()

  return (
    <div className="h-full" {...cardEventHandlers}>
      <Link
        to={product.url}
        title={product.name}
        className="absolute z-10 left-0 top-0 w-full h-full"
      ></Link>

      <div className="relative w-full group-hover:shadow-2xl">
        {isCardHovered && (
          <>
            <div className="absolute bottom-0 left-0 z-50 px-1">
              <BtnAddToWishList showText={false} />
            </div>
            <div className="absolute bottom-0 right-0 z-50 px-1">
              <BtnAddToCart product={product} showText={false} />
            </div>
          </>
        )}

        <ProductImage
          type={PRODUCT.TYPE.BOOK}
          productName={product.name}
          productUrl={product.url}
          productImage={product.images}
          productStock={product.stock}
          productDiscountPerc={product.discount_percentage}
          productCardHovered={isCardHovered}
        />
      </div>

      <h3 className="text-lg font-medium leading-tight mt-2 line-clamp-2">
        <ProductName productName={product.name} />
      </h3>
      <h4 className="text-gray-400 text-sm line-clamp-2">
        <ProductAuthors authors={product.authors} />
      </h4>
      <div className="text-lg pt-3">
        <span className="float-left leading-none block">
          <ProductPrice
            productPrice={product.price}
            productDiscountPerc={product.discount_percentage}
            productDiscountPrice={product.discount_price}
          />
        </span>

        <span className="float-right leading-none mr-3 text-sm">
          <MyIconStar />
          <span className="ml-0.5 text-sm text-gray-400">{product.rating}</span>
        </span>
        <div className="clear-both"></div>
      </div>
    </div>
  )
}
