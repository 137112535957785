import { useState, useEffect } from 'react'
import { ApiConsumer } from 'middleware/api-consumer'
import AccessoriesCard from 'components/product/accessories-card'
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import CarouselSlider from 'components/common/carousel-slider'
import { useProduct } from 'hooks/product'
import { AppLoader } from 'components/loader/app-loader'

export default function FeaturedAccessoriesCard({ type, sliderSettings = {} }) {
  let { getProduct } = useProduct()
  const [dataProducts, setDataProducts] = useState([])
  const [productLoading, setProductLoading] = useState(true)

  useEffect(() => {
    ApiConsumer.get(type.ROUTE)
      .then((res) => {
        const dataProducts = res.data.map((dataProduct) => {
          return getProduct(dataProduct)
        })
        setDataProducts(dataProducts)
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setProductLoading(false)
      })
  }, [type])

  const sliderContent = dataProducts.map((product) => (
    <div
      key={product.id}
      className="h-full relative bg-white basis-2/12 shadow hover:cursor-pointer hover:shadow-xl"
    >
      <AccessoriesCard product={product} />
    </div>
  ))

  return (
    <>
      <div className="w-full featured-slider">
        {productLoading ? (
          <div className="relative p-10 w-full">
            <AppLoader sectionLoading={productLoading} />
          </div>
        ) : (
          <CarouselSlider
            sliderContent={sliderContent}
            sliderSettings={sliderSettings}
          />
        )}
      </div>
    </>
  )
}
