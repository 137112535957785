import { HeartIcon } from '@heroicons/react/24/solid'

export default function BtnWishlistIcon({ showText = false }) {
  return (
    <button
      type="button"
      className="group space-x-1 flex items-center text-sm font-medium text-gray-600 hover:text-gray-900"
    >
      <HeartIcon
        className="h-8 w-8 flex-shrink-0 bg-red-400 text-white rounded-full p-1.5"
        aria-hidden="true"
      />
      <span>{showText && `Wishlist`}</span>
    </button>
  )
}
