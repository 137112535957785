import React from 'react'
import { Link } from 'react-router-dom'

export default function ProductSupplier({ supplierName, supplierUrl }) {
  return (
    <Link className="hover:underline" to={supplierUrl}>
      {supplierName}
    </Link>
  )
}
