import React from 'react'
import { APP_NAME } from 'config'
import ProductSale from 'components/product/product-sale'
import ProductOutOfStock from 'components/product/product-out-of-stock'

export default function ProductImage({
  type,
  productName,
  productUrl,
  productImage,
  productStock,
  productDiscountPerc,
  pageView = false,
}) {
  return (
    <div className="relative">
      <img
        src={`${
          productImage.length
            ? `/` + type.IMAGES + `/` + productImage
            : `https://placehold.co/300x400/png?font=roboto&text=` +
              APP_NAME +
              `.`
        }`}
        alt={productName}
        className="w-full h-auto"
      />

      {!pageView && (
        <>
          <ProductSale
            productStock={productStock}
            productDiscountPerc={productDiscountPerc}
            position="absolute"
          />
          <ProductOutOfStock productStock={productStock} position="absolute" />
        </>
      )}
    </div>
  )
}
