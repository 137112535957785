import { NavLink } from 'react-router-dom'
import SeoPageHeaders from 'components/seo/seo-page-headers'
import SearchBar from 'components/common/search-bar'

export default function NotFound() {
  return (
    <>
      <SeoPageHeaders title={`404 Page Not Found`} />

      <main className="text-gray-800">
        <section className="relative py-5">
          <SearchBar isHomePage={false} />
        </section>
        <section className="relative py-7 bg-gray-100">
          <div className="flex-grow mx-auto max-w-7xl w-full flex flex-col px-4 sm:px-6 lg:px-8">
            <div className="flex-shrink-0 my-auto py-16">
              <p className="text-sm font-semibold text-secondary uppercase tracking-wide text-center">
                404 error
              </p>
              <h1 className="mt-2 text-4xl font-extrabold text-secondary tracking-tight sm:text-5xl text-center">
                Page not found
              </h1>
              <p className="mt-2 text-base text-gray-500 text-center">
                Sorry, we couldn't find the page you're looking for.
              </p>
              <div className="mt-6 text-center">
                <NavLink
                  to="/"
                  className="text-base font-medium text-accent hover:text-sky-600 text-center"
                >
                  Go back to Homepage<span aria-hidden="true"> &rarr;</span>
                </NavLink>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
