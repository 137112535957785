export default function ModalNotifyWhenAvailable() {
  return (
    <>
      <form
        className="space-y-6"
        onSubmit={(event) => {
          event.preventDefault()
        }}
        method="POST"
      ></form>
    </>
  )
}
