import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { ApiConsumer } from 'middleware/api-consumer'
import ProductCard from 'components/product/product-card'
import { useProduct } from 'hooks/product'

export default function FeaturedCard({ type }) {
  let { getProduct } = useProduct()
  const [dataProducts, setDataProducts] = useState([])

  useEffect(() => {
    ApiConsumer.get(type.ROUTE)
      .then((res) => {
        const dataProducts = res.data.map((dataProduct) => {
          return getProduct(dataProduct)
        })
        setDataProducts(dataProducts)
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {})
  }, [type])

  return (
    <>
      <h2 className="w-full text-2xl font-semibold mb-3">
        <span className="float-left">{type.ICON}</span>
        <span className="float-left font-merriweather hover:underline hover:cursor-pointer">
          {type.TITLE}
        </span>
      </h2>
      <div className="flex flex-1 flex-row justify-center w-full space-x-4">
        {dataProducts.slice(0, 3).map((product) => (
          <div
            className="relative bg-white basis-1/3 p-2 shadow group"
            key={product.id}
          >
            <ProductCard product={product} />
          </div>
        ))}
      </div>
    </>
  )
}
