import { Helmet } from 'react-helmet-async'
import { APP_NAME } from 'config'

export default function SeoPageHeaders({
  title = 'Home',
  description = '',
  keywords = '',
}) {
  return (
    <>
      <Helmet>
        <title>
          {title} - {APP_NAME}
        </title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
      </Helmet>
    </>
  )
}
