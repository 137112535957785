import { PRODUCT } from 'constants/product'
import ProductImage from 'components/product/product-image'
import ProductName from 'components/product/product-name'
import ProductAuthors from 'components/product/product-authors'
import ProductPrice from 'components/product/product-price'
import MyIconStar from 'components/icons/star'

export default function AccessoriesCard({ product }) {
  return (
    <>
      <div className="w-full">
        <ProductImage
          type={PRODUCT.TYPE.ACCESSORY}
          productName={product.name}
          productUrl={product.url}
          productImage={product.images}
          productStock={product.stock}
          productDiscountPerc={product.discount_percentage}
        />
      </div>
      <div className="p-2">
        <h3 className="text-lg font-medium leading-tight mt-2 line-clamp-2">
          <ProductName productName={product.name} />
        </h3>
        <h4 className="text-gray-400 text-sm line-clamp-2">
          <ProductAuthors authors={product.authors} />
        </h4>
        <div className="text-lg mt-3">
          <span className="float-left leading-none block">
            <ProductPrice
              productPrice={product.price}
              productDiscountPerc={product.discount_percentage}
              productDiscountPrice={product.discount_price}
            />
          </span>

          <span className="float-right leading-none mr-3">
            <MyIconStar />
            <span className="ml-0.5 text-sm text-gray-400">
              {product.rating}
            </span>
          </span>
          <div className="clear-both"></div>
        </div>
      </div>
    </>
  )
}
