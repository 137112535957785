import { HeartIcon } from '@heroicons/react/24/solid'

export default function BtnAddToWishList({ showText = true }) {
  return (
    <button
      type="button"
      className="cursor-pointer w-full rounded-sm bg-gray-100 hover:bg-gray-200 text-center text-sm p-2 my-1 font-medium whitespace-nowrap"
    >
      <HeartIcon
        className={`h-4 w-4 flex-shrink-0 inline-block ${
          showText ? `-mt-1` : `-mt-0.5`
        }`}
        aria-hidden="true"
      />
      {showText && ' Add to Wish List'}
    </button>
  )
}
