export default function BtnPrimary({ text, background = '', icon = '' }) {
  return (
    <button
      type="button"
      className={`${background} rounded-sm inline-block font-merriweather hover:cursor-pointer px-3 py-2 whitespace-nowrap`}
      title={text}
    >
      {text} {icon}
    </button>
  )
}
