import MyIconStar from 'components/icons/star'
import MyIconStarHalf from 'components/icons/star-half'
import MyIconStarEmpty from 'components/icons/star-empty'

export default function ReviewStars() {
  return (
    <>
      <MyIconStar size="medium" />
      <MyIconStar size="medium" />
      <MyIconStar size="medium" />
      <MyIconStarHalf size="medium" />
      <MyIconStarEmpty size="medium" />
    </>
  )
}
