import { API_ROUTES } from 'routes/api'
import {
  ArrowUpOnSquareStackIcon,
  StarIcon,
  CheckBadgeIcon,
  FireIcon,
} from '@heroicons/react/24/outline'

export const GLOBAL = {
  URLS: {
    SLIDERS_SRC: '/sliders/',
    BANNERS_SRC: '/banners/',
    PRODUCT_PREFIX: 'PID',
    SUPPLIER_PREFIX: 'SID',
    AUTHOR_PREFIX: 'BID',
  },
  HOME: {
    FEATURED: {
      TOP_LEFT: {
        TITLE: 'Featured',
        ICON: <CheckBadgeIcon className="h-7 w-7" aria-hidden="true" />,
        ROUTE: API_ROUTES.PRODUCTS.FEATURED,
      },
      TOP_RIGHT: {
        TITLE: 'New Arrivals',
        ICON: <StarIcon className="h-7 w-7" aria-hidden="true" />,
        ROUTE: API_ROUTES.PRODUCTS.NEW,
      },
      BOTTOM_LEFT: {
        TITLE: 'Top Rated',
        ICON: (
          <ArrowUpOnSquareStackIcon className="h-7 w-7" aria-hidden="true" />
        ),
        ROUTE: API_ROUTES.PRODUCTS.TOP_RATED,
      },
      BOTTOM_RIGHT: {
        TITLE: 'Best Sellers',
        ICON: <FireIcon className=" h-7 w-7" aria-hidden="true" />,
        ROUTE: API_ROUTES.PRODUCTS.BEST_DEALS,
      },
    },
  },
  SLIDER: {
    TOP10: {
      TITLE: 'TOP 10',
      ROUTE: API_ROUTES.PRODUCTS.TOP10,
      QUOTES: [
        '“Sometimes this bag gets heavy from carrying the weight of our tears diluted in laughter.“ ― Elizabeth Awori',
        "“If you don't like someone's story, write your own.” ― Chinua Achebe",
        'Stories can conquer fear, you know. They can make the heart bigger” ― Ben Okri.',
      ],
      BG_DARK: 'bg-emerald-600',
      BG_LIGHT: 'bg-emerald-500',
    },
    AWARD_WINNING: {
      TITLE: 'Award Winning',
      ROUTE: API_ROUTES.PRODUCTS.AWARD_WINNING,
      QUOTES: [
        "“Looking at a king's mouth, ' said an old man, 'one would think he never sucked at his mother's breast.“ ― Chinua Achebe",
        '“Humans, not places, make memories.” ― Ama Ata Aidoo',
        '“The greatest threat to freedom is the absence of criticism” ― Wole Soyinka',
      ],
      BG_DARK: 'bg-cyan-600',
      BG_LIGHT: 'bg-cyan-500',
    },
    CONSIDERATIONS: {
      TITLE: 'To Consider',
      ROUTE: API_ROUTES.PRODUCTS.CONSIDERATIONS,
      QUOTES: [
        "“Looking at a king's mouth, ' said an old man, 'one would think he never sucked at his mother's breast.“ ― Chinua Achebe",
        '“Humans, not places, make memories.” ― Ama Ata Aidoo',
        '“The greatest threat to freedom is the absence of criticism” ― Wole Soyinka',
      ],
      BG_DARK: 'bg-gray-400',
      BG_LIGHT: 'bg-gray-300',
    },
    ACCESSORIES: {
      TITLE: 'Gifts & Accessories',
      ROUTE: API_ROUTES.PRODUCTS.ACCESSORIES,
    },
  },
  SOCIAL: {
    FACEBOOK: '',
    TWITTER: '',
    INSTAGRAM: '',
    YOUTUBE: '',
  },
  FORMS: {
    TYPES: {
      TEXT: `text`,
      TEXTAREA: `textarea`,
      EMAIL: `email`,
      CONTACT: `contact`,
      IDNUM: `idnum`,
      SELECT: `select`,
      CHECKBOX: `checkbox`,
      CONSENT: `consent`,
    },
  },
}
