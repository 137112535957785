import { useState, useEffect } from 'react'
import { GLOBAL } from 'constants/global'
import { APP_NAME } from 'config'
import { API_ROUTES } from 'routes/api'
import { ApiConsumer } from 'middleware/api-consumer'
import SeoPageHeaders from 'components/seo/seo-page-headers'
import SearchBar from 'components/common/search-bar'
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import FeaturedCard from 'components/home/featured-card'
import WideSliderCard from 'components/home/wide-slider-card'
import CarouselSlider from 'components/common/carousel-slider'
import FeaturedAccessoriesCard from 'components/home/featured-accessories-card'
import BtnPrimary from 'components/buttons/btn-primary'

export default function Home() {
  const [dataProductsCategories, setDataProductsCategories] = useState([])
  const [dataHomeSliders, setDataHomeSliders] = useState([])
  const [promoSideBanners, setPromoSideBanners] = useState([])

  useEffect(() => {
    ApiConsumer.get(API_ROUTES.PRODUCTS.CATEGORIES)
      .then((res) => {
        setDataProductsCategories(res.data)
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {})

    ApiConsumer.get(API_ROUTES.HOME.SLIDERS)
      .then((res) => {
        setDataHomeSliders(res.data)
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {})

    ApiConsumer.get(API_ROUTES.HOME.BANNERS)
      .then((res) => {
        setPromoSideBanners(res.data)
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {})
  }, [])

  const sliderContent = dataHomeSliders?.map((slider, i) => (
    <a
      key={i}
      href={slider.link}
      className="hover:cursor-pointer"
      title={slider.name}
    >
      <img
        alt={slider.name}
        src={GLOBAL.URLS.SLIDERS_SRC + slider.img}
        className="m-auto w-auto h-full"
      />
    </a>
  ))

  return (
    <>
      <SeoPageHeaders title={`Africa's Largest Online Book Store`} />
      <main className="text-gray-800">
        <section className="relative pt-5 pb-7">
          <SearchBar isHomePage={true} />

          <div className="mx-auto flex max-w-7xl px-8">
            <div className="w-full max-w-7xl text-left flex">
              <div className="w-1/5 items-stretch border-l border-r border-gray-200">
                <ul className="text-sm py-2">
                  {dataProductsCategories.map((category, i) => (
                    <li
                      key={i}
                      className="px-4 py-2 block hover:cursor-pointer hover:bg-amber-200"
                    >
                      {category.name}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="w-4/5 items-stretch border-r border-gray-200">
                <div className="flex space-x-4 p-4 pb-0 h-full">
                  <div className="w-8/12">
                    <CarouselSlider sliderContent={sliderContent} />
                  </div>
                  <div className="w-4/12">
                    {promoSideBanners.map((banner, i) => (
                      <div
                        key={i}
                        className={`${
                          i === promoSideBanners.length - 1 ? `h-4/6` : `h-1/6`
                        } p-0.5 border border-gray-200 bg-white shadow hover:cursor-pointer`}
                      >
                        <div className="w-full bg-white h-full overflow-hidden">
                          {
                            <img
                              alt={banner.name}
                              src={GLOBAL.URLS.BANNERS_SRC + banner.img}
                              className="m-auto w-auto h-full"
                            />
                          }
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mx-auto flex max-w-7xl px-8">
            <div className="w-full max-w-7xl text-left flex">
              <div className="w-1/5 items-stretch rounded-bl-md border border-gray-200">
                <h2 className="p-4 text-sm uppercase hover:cursor-pointer">
                  <span className="float-left">More</span>
                  <ChevronRightIcon
                    className="text-gray-800 ml-2 h-5 w-5 float-right"
                    aria-hidden="true"
                  />
                  <div className=" clear-both"></div>
                </h2>
              </div>
              <div className="w-4/5 items-stretch border-b border-r border-gray-200 rounded-br-md">
                <div className="p-4 space-x-2 pt-4 text-sm text-black">
                  <span className="rounded-full bg-amber-200 px-3 py-1 hover:cursor-pointer">
                    Fiction
                  </span>
                  <span className="rounded-full bg-amber-200 px-3 py-1 hover:cursor-pointer">
                    Non-Fiction
                  </span>
                  <span className="rounded-full bg-amber-200 px-3 py-1 hover:cursor-pointer">
                    Pan-African
                  </span>
                  <span className="rounded-full bg-amber-200 px-3 py-1 hover:cursor-pointer">
                    Summer Reads
                  </span>
                  <span className="rounded-full bg-amber-200 px-3 py-1 hover:cursor-pointer">
                    Book Clubs
                  </span>
                  <span className="rounded-full bg-amber-200 px-3 py-1 hover:cursor-pointer">
                    Publishers
                  </span>
                  <span className="rounded-full bg-amber-200 px-3 py-1 hover:cursor-pointer">
                    Accessories
                  </span>
                  <span className="rounded-full bg-amber-200 px-3 py-1 hover:cursor-pointer">
                    Join For Free{' '}
                    <ChevronRightIcon
                      className="text-gray-800 -ml-1 -mt-0.5 h-4 w-4 inline"
                      aria-hidden="true"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative py-7 bg-gray-100">
          <div className="mx-auto px-8 max-w-7xl">
            <div className="flex space-x-4">
              <div className="flex flex-col basis-6/12 items-stretch">
                <FeaturedCard type={GLOBAL.HOME.FEATURED.TOP_LEFT} />
              </div>
              <div className="flex flex-col basis-6/12 items-stretch">
                <FeaturedCard type={GLOBAL.HOME.FEATURED.TOP_RIGHT} />
              </div>
            </div>
            <div className="flex space-x-4 mt-7">
              <div className="flex flex-col basis-6/12 items-stretch">
                <FeaturedCard type={GLOBAL.HOME.FEATURED.BOTTOM_LEFT} />
              </div>
              <div className="flex flex-col basis-6/12 items-stretch">
                <FeaturedCard type={GLOBAL.HOME.FEATURED.BOTTOM_RIGHT} />
              </div>
            </div>
          </div>
        </section>

        <section className="relative py-7">
          <div className="mx-auto px-8 max-w-7xl">
            <div className="flex w-full items-stretch space-x-2">
              <WideSliderCard
                type={GLOBAL.SLIDER.TOP10}
                sliderSettings={{
                  slidesToShow: 5,
                  dots: false,
                  infinite: false,
                  autoplay: false,
                }}
                max="10"
              />
            </div>
          </div>
        </section>

        <section className="relative py-7">
          <div className="mx-auto px-8 max-w-7xl">
            <div className="flex w-full items-stretch space-x-2">
              <WideSliderCard
                type={GLOBAL.SLIDER.AWARD_WINNING}
                sliderSettings={{
                  slidesToShow: 5,
                  dots: false,
                  autoplay: false,
                }}
              />
            </div>
          </div>
        </section>

        <section className="relative py-7 bg-gray-100 overflow-x-hidden">
          <div className="mx-auto px-8 max-w-7xl">
            <div className="flex-1 w-full items-stretch">
              <div className="mb-3 w-full">
                <h2 className="float-left text-2xl font-semibold">
                  <span className="font-merriweather hover:underline hover:cursor-pointer">
                    {GLOBAL.SLIDER.ACCESSORIES.TITLE}
                  </span>
                </h2>
                <div className="float-right">
                  <BtnPrimary
                    text={`View More ${GLOBAL.SLIDER.ACCESSORIES.TITLE}`}
                    background="bg-amber-200"
                    icon={
                      <ChevronRightIcon
                        className={`-ml-1 -mt-0.5 h-5 w-5 inline`}
                        aria-hidden="true"
                      />
                    }
                  />
                </div>
                <div className="clear-both"></div>
              </div>
              <FeaturedAccessoriesCard
                type={GLOBAL.SLIDER.ACCESSORIES}
                sliderSettings={{
                  pauseOnHover: true,
                  autoplaySpeed: 2000,
                  slidesToShow: 6,
                  dots: false,
                }}
              />
            </div>
          </div>
        </section>

        <section className="relative py-7 bg-gray-100">
          <div className="mx-auto px-8 max-w-7xl">
            <div className="flex justify-center text-center w-full items-stretch font-merriweather ">
              <div className="flex items-center justify-center relative w-1/2 p-6 bg-red-700 rounded-bl-lg rounded-tl-lg hover:cursor-pointer">
                <div className="z-20">
                  <p className="font-bold text-4xl text-gray-100">
                    For Publishers & Creators
                  </p>
                  <p className="text-lg text-gray-100">
                    Sell on {APP_NAME}, Africa's largest online book store.
                    Providing you with the resources and marketing you need to
                    increase your sales and extend your reach.
                  </p>
                  <div className="mt-3">
                    <BtnPrimary
                      text={`Sell on ${APP_NAME}`}
                      background="bg-gray-100"
                    />
                  </div>
                </div>
                <div
                  className={`chevron absolute z-10 bg-red-900 top-0 left-0 h-full w-3/5 rounded-bl-md rounded-tl-md`}
                ></div>
              </div>
              <div className="flex items-center justify-center relative w-1/2 p-6 bg-yellow-500 rounded-br-md rounded-tr-md hover:cursor-pointer">
                <div className="z-20">
                  <p className="font-bold text-4xl text-gray-100">
                    For Book Clubs
                  </p>
                  <p className="text-lg text-gray-100">
                    Register your book club and enjoy benefits of bulk
                    discounts, exclusive events, collectors items and many more.
                  </p>
                  <div className="mt-3">
                    <BtnPrimary
                      text={`Register Your Book Club`}
                      background="bg-gray-100"
                    />
                  </div>
                </div>
                <div
                  className={`chevron absolute z-10 bg-yellow-600 top-0 left-0 h-full w-3/5`}
                ></div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
