import { API_BASE_URL } from 'config'

export const API_ROUTES = {
  HOME: {
    SLIDERS: `${API_BASE_URL}/dataHomeSliders.json`,
    BANNERS: `${API_BASE_URL}/dataHomeBanners.json`,
  },
  PRODUCTS: {
    ALL: `${API_BASE_URL}/dataProducts.json`,
    FEATURED: `${API_BASE_URL}/dataProductsFeatured.json`,
    NEW: `${API_BASE_URL}/dataProductsNew.json`,
    TOP_RATED: `${API_BASE_URL}/dataProductsTopRated.json`,
    BEST_DEALS: `${API_BASE_URL}/dataProductsBestDeals.json`,
    TOP10: `${API_BASE_URL}/dataProductsTop10.json`,
    AWARD_WINNING: `${API_BASE_URL}/dataProductsAwardWinning.json`,
    ACCESSORIES: `${API_BASE_URL}/dataProductsAccessories.json`,
    CATEGORIES: `${API_BASE_URL}/dataProductsCategories.json`,
    CONSIDERATIONS: `${API_BASE_URL}/dataProductsTop10.json`,
  },
  SUPPLIERS: {
    ALL: `${API_BASE_URL}/dataSuppliers.json`,
    PRODUCTS: function (supplier_id) {
      return `${API_BASE_URL}/supplier/${supplier_id}`
    },
  },
}
