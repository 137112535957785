import { HandThumbUpIcon } from '@heroicons/react/24/solid'
import ReviewStars from 'components/reviews/review-stars'

export default function ReviewCard({ product }) {
  return (
    <div className="py-6">
      <ReviewStars />
      <div className="font-bold my-2">Diana V. on 1 March 2020</div>
      <div className="text-sm">
        Reading this book contained and gave me absolutely everything I love
        about reading. It encompasses everything I love about the reading
        process. I loved it so much I know I won't be able to write a coherent
        or worthy review; there's no way for me to do this story justice, except
        to recommend it to many, many people I know, something I've already
        started to do.
      </div>
      <div className="text-sm">
        <span className="float-left mt-3 hover:cursor-pointer px-3 py-2 border hover:bg-gray-100">
          <HandThumbUpIcon
            className="text-gray-800 -mt-0.5 h-5 w-5 inline"
            aria-hidden="true"
          />{' '}
          This review was helpful (6)
        </span>

        <span className="float-left mt-3 hover:cursor-pointer px-3 py-2 hover:underline">
          Report this review
        </span>
        <div className="clear-both"></div>
      </div>
    </div>
  )
}
