import { APP_ID } from 'config'

const axios = require('axios')
export const ApiConsumer = axios.create()

export const isAuthenticated = () => {
  return !!localStorage.getItem(APP_ID)
}

ApiConsumer.interceptors.request.use(
  (request) => {
    if (isAuthenticated())
      request.headers.Authorization = `Api-Key ${
        JSON.parse(localStorage.getItem(APP_ID))?.token
      }`
    // else
    //     window.location.replace(PORTAL.HOME);
    return request
  },
  (error) => {
    return Promise.reject(error)
  },
)
