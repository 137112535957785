import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { API_ROUTES } from 'routes/api'
import { GLOBAL } from 'constants/global'
import { ApiConsumer } from 'middleware/api-consumer'
import { AppLoader } from 'components/loader/app-loader'
import { ShoppingCartIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import SeoPageHeaders from 'components/seo/seo-page-headers'
import SearchBar from 'components/common/search-bar'
import NotFound from 'pages/404'
import MyIconShare from 'components/icons/share'
import ReviewStars from 'components/reviews/review-stars'
import BtnPrimary from 'components/buttons/btn-primary'
import WideSliderCard from 'components/home/wide-slider-card'
import { useProduct } from 'hooks/product'
import LatestItem from 'components/product/latest-item'

export default function SupplierPage() {
  const ulrParams = useParams()
  const supplierSlug = ulrParams?.supplier_slug
  const supplierId = ulrParams?.supplier_id
  const navigate = useNavigate()
  let { getProduct } = useProduct()
  const [dataSupplier, setDataSupplier] = useState([])
  const [validPage, setValidPage] = useState(true)
  const [supplierLoading, setSupplierLoading] = useState(true)
  const [latestProduct, setLatestProduct] = useState({})

  useEffect(() => {
    //API_ROUTES.SUPPLIERS.PRODUCTS(supplierId)
    ApiConsumer.get(API_ROUTES.PRODUCTS.ALL)
      .then((res) => {
        if (res.data.length !== 0) setLatestProduct(getProduct(res.data[0]))
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {})
  }, [])

  useEffect(() => {
    ApiConsumer.get(API_ROUTES.SUPPLIERS.ALL)
      .then((res) => {
        let supplier = res.data.filter(
          (supplier) => supplier.id.toString() === supplierId.toString(),
        )[0]

        /*
            Validate supplier ID but redirect to correct slug if supplier slug is incorrect
            Else go to 404 if supplier ID is invalid
          */
        if (supplier) {
          if (
            '/' +
              supplierSlug +
              '/' +
              GLOBAL.URLS.SUPPLIER_PREFIX +
              supplierId !==
            supplier.url
          )
            navigate(supplier.url)

          setDataSupplier(supplier)
        } else setValidPage(false)
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setSupplierLoading(false)
      })
  }, [supplierSlug, supplierId])

  if (!validPage) return <NotFound />

  return (
    <>
      <SeoPageHeaders title={dataSupplier.name} />

      <main className="text-gray-800">
        <section className="relative py-5">
          <SearchBar isHomePage={false} />
        </section>

        <section className="relative bg-gray-100">
          <div className="mx-auto px-8 max-w-7xl relative flex space-x-4 pt-7">
            <div className="w-9/12 space-y-8 mb-7">
              <section>
                {supplierLoading ? (
                  <div className="relative p-10 w-full">
                    <AppLoader sectionLoading={supplierLoading} />
                  </div>
                ) : (
                  <div className="p-6 border border-gray-200 shadow bg-white">
                    <h1 className="font-merriweather font-extrabold text-3xl mb-3">
                      {dataSupplier.name}
                    </h1>
                    <p
                      className="mb-3"
                      dangerouslySetInnerHTML={{
                        __html: dataSupplier.description,
                      }}
                    ></p>
                    <Link to="/">
                      <BtnPrimary
                        text={`Shop the Product Range`}
                        background="bg-amber-200"
                        icon={
                          <ShoppingCartIcon
                            className="float-left text-gray-800 mt-0.5 mr-0.5 h-5 w-5 inline-block"
                            aria-hidden="true"
                          />
                        }
                      />
                    </Link>

                    {Object.keys(latestProduct).length !== 0 && (
                      <>
                        <h3 className="w-full text-2xl font-semibold font-merriweather mb-3 mt-10">
                          Latest
                        </h3>
                        <LatestItem product={latestProduct} />
                      </>
                    )}

                    <h3 className="w-full text-2xl font-semibold font-merriweather mb-3 mt-10">
                      Most Popular
                    </h3>

                    <WideSliderCard
                      type={GLOBAL.SLIDER.CONSIDERATIONS}
                      sliderSettings={{
                        slidesToShow: 6,
                        dots: false,
                        autoplay: false,
                      }}
                      showCard={false}
                    />
                  </div>
                )}
              </section>
            </div>
            <div className="w-3/12 mb-7">
              <div className="p-6 border relative border-gray-200 shadow bg-white">
                {supplierLoading ? (
                  <div className="relative p-10 w-full">
                    <AppLoader sectionLoading={supplierLoading} />
                  </div>
                ) : (
                  <>
                    <div className="float-right">
                      <span className="mt-3 font-merriweather hover:cursor-pointer bg-white">
                        <MyIconShare /> Share
                      </span>
                    </div>
                    <div className="clear-both"></div>
                    <div className="text-center basis-1/2">
                      <p className="my-3">
                        {dataSupplier.name} products have an average rating of
                      </p>
                      <div className="text-4xl font-semibold">4.2 out of 5</div>
                      <div>
                        <ReviewStars />
                      </div>

                      <div className="font-bold my-3">from 97 reviews</div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
