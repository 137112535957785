import { Outlet } from 'react-router-dom'
import CookieConsent from 'react-cookie-consent'
import Header from 'components/common/header'
import Footer from 'components/common/footer'
import { SITE_HOST } from 'config'

export default function MainLayout() {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="appCookieConsent"
        buttonClasses="text-sm"
        containerClasses="text-sm"
        buttonStyle={{
          background: 'rgb(234 179 8)',
          borderRadius: '9999px',
          fontSize: '14px',
          padding: '8px 25px',
        }}
        style={{ color: 'rgb(229 231 235)', background: 'rgb(55 65 81)' }}
        expires={150}
      >
        Our site uses cookies to enhance your user experience, improve
        functionality, analyse site traffic, and better serve advertising. By
        continuing to use this website, you consent to and accept the use of
        cookies in accordance with our{' '}
        <a
          href={`${SITE_HOST}/privacy-policy/`}
          style={{ textDecoration: 'underline' }}
        >
          Privacy Policy
        </a>
      </CookieConsent>
    </>
  )
}
