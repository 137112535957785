import { useState, useEffect } from 'react'
import { API_ROUTES } from 'routes/api'
import { ApiConsumer } from 'middleware/api-consumer'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid'

export default function SearchBar({ isHomePage = false }) {
  const [dataProductsCategories, setDataProductsCategories] = useState([])
  const [showCategories, setShowCategories] = useState(false)

  useEffect(() => {
    ApiConsumer.get(API_ROUTES.PRODUCTS.CATEGORIES)
      .then((res) => {
        setDataProductsCategories(res.data)
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {})
  }, [])

  return (
    <>
      <div className="mx-auto flex max-w-7xl px-8">
        <div className="w-full max-w-7xl text-left flex">
          <div
            className={`w-1/5 items-stretch bg-gray-900 relative ${
              !isHomePage ? `rounded-tl-md rounded-bl-md` : `rounded-tl-md`
            }`}
          >
            <h2
              className="p-4 font-bold text-white hover:cursor-pointer"
              onMouseOver={() => setShowCategories(true)}
              onMouseOut={() => setShowCategories(false)}
            >
              <span className="float-left uppercase text-sm">
                Shop By Category
              </span>
              <ChevronDownIcon
                className="ml-2 h-5 w-5 float-right"
                aria-hidden="true"
              />
              <div className="clear-both"></div>
            </h2>
            {showCategories && (
              <div
                className="w-full absolute z-50"
                onMouseOver={() => setShowCategories(true)}
                onMouseOut={() => setShowCategories(false)}
              >
                <div className="w-full items-stretch border-l border-r border-b border-gray-200 bg-white rounded-br-md rounded-bl-md">
                  <ul className="text-sm py-2">
                    {dataProductsCategories.map((category, i) => (
                      <li
                        key={i}
                        className="px-4 py-2 block hover:cursor-pointer hover:bg-amber-200"
                      >
                        {category.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
          <div
            className={`w-4/5 items-stretch bg-gray-700  ${
              !isHomePage ? `rounded-tr-md rounded-br-md` : `rounded-tr-md`
            }`}
          >
            <div className="flex px-4 py-2 h-full">
              <div className="flex-grow items-stretch focus-within:z-10">
                <input
                  type="text"
                  placeholder="Search by title, author, ISBN..."
                  className="block text-gray-600 px-3 py-1 h-full w-full rounded-l-full border-0 focus:border-0 focus:ring-0"
                />
              </div>
              <div className="inline-flex items-center border-l border-gray-200">
                <label htmlFor="categories" className="sr-only">
                  Categories
                </label>
                <select
                  id="categories"
                  name="categories"
                  className="h-full border-0 py-0 pl-4 pr-7 text-sm text-black bg-gray-100 hover:bg-gray-200 focus:border-0 focus:ring-0 hover:cursor-pointer"
                  defaultValue="All"
                >
                  <option value="All">All Categories</option>
                  <option value="Fiction">Fiction</option>
                  <option value="Non-Fiction">Non-Fiction</option>
                  <option value="Pan-African">Pan-African</option>
                  <option value="Novels">Novels</option>
                </select>
              </div>
              <button
                type="button"
                className="inline-flex items-center rounded-r-full pl-3 pr-4 text-sm text-white bg-red-700 hover:bg-red-800 focus:outline-0 focus:ring-0"
              >
                <MagnifyingGlassIcon
                  className="mr-0.5 h-5 w-5 flex-shrink-0 float-left"
                  aria-hidden="true"
                />{' '}
                <span className="float-left">Search</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
