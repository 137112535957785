import { useState, useEffect, useContext } from 'react'
import { GLOBAL } from 'constants/global'
import SeoPageHeaders from 'components/seo/seo-page-headers'
import SearchBar from 'components/common/search-bar'
import { API_ROUTES } from 'routes/api'
import { ApiConsumer } from 'middleware/api-consumer'
import NotFound from 'pages/404'
import CartEmpty from 'components/cart/cart-empty'
import { CartContext } from 'contexts/CartContext'
import CartItem from 'components/cart/cart-item'
import QRCodeExample from 'assets/images/qrcode-example.png'
import { useUtils } from 'hooks/utils'
import BtnPrimary from 'components/buttons/btn-primary'
import { ChevronRightIcon } from '@heroicons/react/24/solid'

export default function CartPage() {
  const [promoSideBanners, setPromoSideBanners] = useState([])
  const { cartItems, itemCount, total } = useContext(CartContext)
  let { formatNumber } = useUtils()

  useEffect(() => {
    ApiConsumer.get(API_ROUTES.HOME.BANNERS)
      .then((res) => {
        setPromoSideBanners(res.data)
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {})
  }, [])

  return (
    <>
      <SeoPageHeaders title={'Shopping Cart'} />

      <main className="text-gray-800">
        <section className="relative py-5">
          <SearchBar isHomePage={false} />
        </section>

        <section className="relative bg-gray-100">
          <div className="mx-auto px-8 max-w-7xl text-sm py-7">
            <h1 className="font-merriweather font-extrabold text-3xl">
              Your Shopping Cart
            </h1>
          </div>
        </section>

        <section className="relative bg-gray-100">
          <div className="mx-auto px-8 max-w-7xl relative flex space-x-4">
            <div className="w-9/12 space-y-4 mb-7">
              {cartItems.length ? (
                <>
                  {cartItems.map((product) => (
                    <div
                      key={product.id}
                      className="p-6 border relative border-gray-200 shadow bg-white"
                    >
                      <CartItem product={product} />
                    </div>
                  ))}
                </>
              ) : (
                <div className="p-6 border relative border-gray-200 shadow bg-white">
                  <CartEmpty isSideBar={false} />
                </div>
              )}
            </div>
            <div className="w-3/12 space-y-1 mb-7">
              {cartItems.length ? (
                <>
                  <div className="p-6 border relative border-gray-200 shadow bg-white">
                    <div className="text-xl font-bold mb-3">Cart Summary</div>
                    <p>{itemCount} items in your cart</p>

                    <div className="w-full font-bold border-b border-gray-200 mb-3 pb-3">
                      Total: {formatNumber(total)}
                    </div>
                    {itemCount > 1 && (
                      <p>
                        Your order has{' '}
                        <strong className="text-red-700">FREE DELIVERY</strong>
                      </p>
                    )}
                    <div className="w-full my-5">
                      <BtnPrimary
                        text={`Proceed to Checkout`}
                        background="bg-amber-200"
                        icon={
                          <ChevronRightIcon
                            className={`-ml-1 -mt-0.5 h-5 w-5 inline`}
                            aria-hidden="true"
                          />
                        }
                      />
                    </div>
                    <div className="w-full text-sm text-gray-500 my-3">
                      <em>
                        Placing an item in your shopping cart does not reserve
                        that item or price. We only reserve stock for your order
                        once payment is received.
                      </em>
                    </div>
                    <hr className="my-3" />
                    <div className="flex">
                      <img
                        alt="Product QR Code"
                        src={QRCodeExample}
                        className="w-auto h-16"
                      />
                      <p className="pl-2 leading-tight text-sm">
                        <span className="font-bold">Open on mobile</span>
                        <br />
                        <span>
                          Scan the QR code using your mobile phone camera to
                          open this page on your mobile phone.
                        </span>
                      </p>
                    </div>
                  </div>

                  <ul className="w-full p-4 list-disc pl-6">
                    <li>
                      <a href="#." className="cursor-pointer hover:underline">
                        30 Day Exchanges
                      </a>
                    </li>
                    <li>
                      <a href="#." className="cursor-pointer hover:underline">
                        Delivery Information
                      </a>
                    </li>
                    <li>
                      <a href="#." className="cursor-pointer hover:underline">
                        Returns Policy
                      </a>
                    </li>
                  </ul>
                </>
              ) : (
                <>
                  {promoSideBanners.slice(0, 2).map((banner, i) => (
                    <div
                      key={i}
                      className={`p-0.5 border border-gray-200 bg-white shadow hover:cursor-pointer`}
                    >
                      <div className="w-full bg-white h-full overflow-hidden">
                        {
                          <img
                            alt={banner.name}
                            src={GLOBAL.URLS.BANNERS_SRC + banner.img}
                            className="m-auto w-auto h-full"
                          />
                        }
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
