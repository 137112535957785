import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
export default function Pagination() {
  return (
    <div className="m-auto">
      <div className="flex flex-1 justify-between sm:hidden">
        <a
          href="#"
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white p-2 text-sm font-medium hover:bg-gray-300"
        >
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          <span>Previous</span>
        </a>
        <a
          href="#"
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white p-2 text-sm font-medium hover:bg-gray-300"
        >
          <span>Next</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </a>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-center">
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <a
              href="#"
              className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white p-2 pr-3 text-sm font-medium hover:bg-gray-300 focus:z-20"
            >
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              <span>Previous</span>
            </a>

            <a
              href="#"
              aria-current="page"
              className="relative z-10 inline-flex items-center border border-gray-700 bg-gray-700 px-4 py-2 text-sm font-medium text-white focus:z-20"
            >
              1
            </a>
            <a
              href="#"
              className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium hover:bg-gray-300 focus:z-20"
            >
              2
            </a>
            <a
              href="#"
              className="relative hidden items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium hover:bg-gray-300 focus:z-20 md:inline-flex"
            >
              3
            </a>

            <a
              href="#"
              className="relative hidden items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium hover:bg-gray-300 focus:z-20 md:inline-flex"
            >
              4
            </a>
            <a
              href="#"
              className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium hover:bg-gray-300 focus:z-20"
            >
              5
            </a>
            <span className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium">
              ...
            </span>
            <a
              href="#"
              className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white p-2 pl-3 text-sm font-medium hover:bg-gray-300 focus:z-20"
            >
              <span>Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </a>
          </nav>
        </div>
      </div>
    </div>
  )
}
