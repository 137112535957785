import { useState, useEffect, useRef, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { PORTAL } from 'routes/portal'
import { PRODUCT } from 'constants/product'
import { API_ROUTES } from 'routes/api'
import { GLOBAL } from 'constants/global'
import { ApiConsumer } from 'middleware/api-consumer'
import { AppLoader } from 'components/loader/app-loader'
import QRCodeExample from 'assets/images/qrcode-example.png'
import {
  BookOpenIcon,
  HandThumbUpIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline'
import WideSliderCard from 'components/home/wide-slider-card'
import SeoPageHeaders from 'components/seo/seo-page-headers'
import SearchBar from 'components/common/search-bar'
import NotFound from 'pages/404'
import ProductImage from 'components/product/product-image'
import ProductName from 'components/product/product-name'
import ProductSupplier from 'components/product/product-supplier'
import ProductAuthors from 'components/product/product-authors'
import { useProduct } from 'hooks/product'
import MyIconStar from 'components/icons/star'
import ReviewStars from 'components/reviews/review-stars'
import MyIconShare from 'components/icons/share'
import ProductPrice from 'components/product/product-price'
import ProductSale from 'components/product/product-sale'
import ProductOutOfStock from 'components/product/product-out-of-stock'
import ReviewCard from 'components/reviews/review-card'
import Pagination from 'components/reviews/pagination'
import ReviewStarsCategory from 'components/reviews/review-stars-category'
import BtnCartIcon from 'components/buttons/btn-cart-icon'
import BtnAddToCart from 'components/buttons/btn-add-to-cart'
import BtnAddToWishList from 'components/buttons/btn-add-to-wishlist'
import BtnPrimary from 'components/buttons/btn-primary'
import BtnWriteReview from 'components/buttons/btn-write-review'
import { CartContext } from 'contexts/CartContext'

export default function ProductPage() {
  const ulrParams = useParams()
  const productSlug = ulrParams?.product_slug
  const productId = ulrParams?.product_id
  const navigate = useNavigate()
  let { getProduct } = useProduct()
  const [dataProduct, setDataProduct] = useState([])
  const [validPage, setValidPage] = useState(true)
  const [productLoading, setProductLoading] = useState(true)
  const [scrollerVisible, setScrollerVisible] = useState(true)
  const { cartItems } = useContext(CartContext)

  useEffect(() => {
    setScrollerVisible(false)
    window.addEventListener('scroll', listenToScroll)
    return () => window.removeEventListener('scroll', listenToScroll)
  }, [])

  const listenToScroll = () => {
    let heightToHideFrom = 600
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    if (winScroll < heightToHideFrom) {
      scrollerVisible && setScrollerVisible(false)
    } else {
      setScrollerVisible(true)
    }
  }

  useEffect(() => {
    ApiConsumer.get(API_ROUTES.PRODUCTS.ALL)
      .then((res) => {
        let product = res.data.filter(
          (product) => product.id.toString() === productId.toString(),
        )[0]

        /*
            Validate product ID but redirect to correct slug if product slug is incorrect
            Else go to 404 if product ID is invalid
          */
        if (product) {
          if (
            '/' + productSlug + '/' + GLOBAL.URLS.PRODUCT_PREFIX + productId !==
            product.url
          )
            navigate(product.url)

          setDataProduct(getProduct(product))
        } else setValidPage(false)
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setProductLoading(false)
      })
  }, [productSlug, productId])

  const moreDetailsRef = useRef(null)
  const executeMoreDetailsScroll = () => {
    moreDetailsRef.current.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    })
  }

  const reviewsRef = useRef(null)
  const executeReviewsScroll = () => {
    reviewsRef.current.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    })
  }

  if (!validPage) return <NotFound />

  return (
    <>
      <SeoPageHeaders title={dataProduct.name} />

      <main className="text-gray-800">
        <section className="relative py-5">
          <SearchBar isHomePage={false} />
        </section>

        <section className="relative bg-gray-100">
          <div className="mx-auto px-8 max-w-7xl text-sm py-7">
            <div className="float-left">
              <a href="#." className=" text-sky-600 hover:underline">
                Non-Fiction
              </a>{' '}
              /{' '}
              <a href="#." className=" text-sky-600 hover:underline">
                Biographies & True Stories
              </a>{' '}
              /{' '}
              <a href="#." className=" text-sky-600 hover:underline">
                Current Affairs
              </a>
            </div>
            <div className="float-right">
              <span className="mt-3 font-merriweather hover:cursor-pointer bg-white px-3 py-2">
                <MyIconShare /> Share
              </span>
            </div>

            <div className="clear-both"></div>
          </div>
        </section>

        <section className="relative bg-gray-100">
          {scrollerVisible && (
            <div
              className={`${
                scrollerVisible ? `block` : `hidden`
              } z-50 sticky top-0 w-full`}
            >
              <div className="flex border border-gray-200 bg-white px-6 py-2">
                <div className={`grow flex items-center justify-center`}>
                  {productLoading ? (
                    <div className="relative p-10 w-full">
                      <AppLoader sectionLoading={productLoading} />
                    </div>
                  ) : (
                    <>
                      <div className="w-10">
                        <ProductImage
                          type={PRODUCT.TYPE.BOOK}
                          productTitle={dataProduct.name}
                          productUrl={dataProduct.url}
                          productImage={dataProduct.images}
                          productStock={dataProduct.stock}
                          productDiscountPerc={dataProduct.discount_percentage}
                          pageView={true}
                        />
                      </div>
                      <div className="font-merriweather ml-2 mr-10 text-xl font-semibold">
                        {dataProduct.name}
                      </div>

                      <div>
                        <ProductSale
                          productStock={dataProduct.stock}
                          productDiscountPerc={dataProduct.discount_percentage}
                          position="relative"
                        />
                        <ProductOutOfStock
                          productStock={dataProduct.stock}
                          position="relative"
                        />
                      </div>

                      <div className="mx-2 font-merriweather">
                        <ProductPrice
                          productPrice={dataProduct.price}
                          productDiscountPerc={dataProduct.discount_percentage}
                          productDiscountPrice={dataProduct.discount_price}
                          size="medium"
                        />
                      </div>

                      <div className="flex space-x-2">
                        <BtnAddToCart product={dataProduct} />
                        <BtnAddToWishList />
                        <BtnCartIcon showText={true} />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="mx-auto px-8 max-w-7xl relative flex space-x-4">
            <div className="w-9/12 space-y-8 mb-7">
              <section>
                {productLoading ? (
                  <div className="relative p-10 w-full">
                    <AppLoader sectionLoading={productLoading} />
                  </div>
                ) : (
                  <div className="flex p-6 border border-gray-200 shadow bg-white">
                    <div className="w-4/12">
                      <ProductImage
                        type={PRODUCT.TYPE.BOOK}
                        productTitle={dataProduct.name}
                        productUrl={dataProduct.url}
                        productImage={dataProduct.images}
                        productStock={dataProduct.stock}
                        productDiscountPerc={dataProduct.discount_percentage}
                        pageView={true}
                      />
                    </div>
                    <div className="w-8/12 py-4 px-6">
                      <h1 className="font-merriweather font-extrabold text-3xl">
                        <ProductName productName={dataProduct.name} />
                      </h1>
                      <h2 className="text-sky-600 text-lg mt-1">
                        <ProductAuthors authors={dataProduct.authors} />
                      </h2>
                      <h2 className="text-gray-400 text-lg mt-1">
                        Publisher:{' '}
                        <ProductSupplier
                          supplierName={dataProduct.supplier.name}
                          supplierUrl={dataProduct.supplier.url}
                        />
                      </h2>

                      <div className="mt-4">
                        <div className="float-left">
                          <ReviewStars rating={dataProduct.rating} />
                          <span className="ml-0.5">{dataProduct.rating}</span>
                        </div>
                        <div className="ml-2 float-left">
                          <span
                            onClick={() => executeReviewsScroll()}
                            className="mx-2 cursor-pointer text-sky-600 hover:underline"
                          >
                            20 Reviews
                          </span>
                          <span
                            onClick={() => executeReviewsScroll()}
                            className="mx-2 cursor-pointer text-sky-600 hover:underline"
                          >
                            Review this item
                          </span>
                        </div>
                        <div className="clear-both"></div>
                      </div>

                      <div className="mt-4 pt-4 border-t border-gray-200">
                        <p>{dataProduct.shortDesc}</p>

                        <div
                          className="mt-3 float-left"
                          onClick={() => executeMoreDetailsScroll()}
                        >
                          <BtnPrimary
                            text={`More Details`}
                            background="bg-amber-200"
                            icon={
                              <BookOpenIcon
                                className="float-left text-gray-800 mt-0.5 mr-0.5 h-5 w-5 inline-block"
                                aria-hidden="true"
                              />
                            }
                          />
                        </div>

                        <div className="mt-3 float-left">
                          <BtnPrimary
                            text={`Recommend to a Friend`}
                            background="bg-white"
                            icon={
                              <HandThumbUpIcon
                                className="float-left text-gray-800 mt-0.5 mr-0.5 h-5 w-5 inline-block"
                                aria-hidden="true"
                              />
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </section>

              <section
                ref={moreDetailsRef}
                className="p-6 border border-gray-200 shadow bg-white"
              >
                <h3 className="w-full text-2xl font-semibold font-merriweather mb-3">
                  Description
                </h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: dataProduct.description,
                  }}
                ></p>
              </section>

              <section className="p-6 border border-gray-200 shadow bg-white">
                <h3 className="w-full text-2xl font-semibold font-merriweather mb-3">
                  Product Information
                </h3>
                <div className="grid grid-flow-row auto-rows-max">
                  <div className="grid grid-cols-3">
                    <div className="px-4 py-2 bg-gray-100 border-b border-gray-200 font-medium">
                      Categories
                    </div>
                    <div className="col-span-2 px-4 py-2 border-b border-gray-200">
                      <a href="#." className=" text-sky-600 hover:underline">
                        Home & Kitchen
                      </a>{' '}
                      /{' '}
                      <a href="#." className=" text-sky-600 hover:underline">
                        Small Household Appliances
                      </a>{' '}
                      /{' '}
                      <a href="#." className=" text-sky-600 hover:underline">
                        Steam Cleaners
                      </a>{' '}
                      /{' '}
                      <a href="#." className=" text-sky-600 hover:underline">
                        Vacuums
                      </a>{' '}
                      /{' '}
                      <a href="#." className=" text-sky-600 hover:underline">
                        Vacuum Accessories
                      </a>{' '}
                      /{' '}
                      <a href="#." className=" text-sky-600 hover:underline">
                        Vacuum Bags
                      </a>
                    </div>
                  </div>

                  <div className="grid grid-cols-3">
                    <div className="px-4 py-2 bg-gray-100 border-b border-gray-200 font-medium">
                      Author(s)
                    </div>
                    <div className="col-span-2 px-4 py-2 border-b border-gray-200">
                      <a href="#." className=" text-sky-600 hover:underline">
                        Mogau Seshoene
                      </a>
                    </div>
                  </div>

                  <div className="grid grid-cols-3">
                    <div className="px-4 py-2 bg-gray-100 border-b border-gray-200 font-medium">
                      Warranty
                    </div>
                    <div className="col-span-2 px-4 py-2 border-b border-gray-200">
                      Limited (30 Days)
                    </div>
                  </div>

                  <div className="grid grid-cols-3">
                    <div className="px-4 py-2 bg-gray-100 border-b border-gray-200 font-medium">
                      ISBN
                    </div>
                    <div className="col-span-2 px-4 py-2 border-b border-gray-200">
                      9780735211292
                    </div>
                  </div>
                  <div className="grid grid-cols-3">
                    <div className="px-4 py-2 bg-gray-100 border-b border-gray-200 font-medium">
                      Book Formats
                    </div>
                    <div className="col-span-2 px-4 py-2 border-b border-gray-200">
                      Paperback / Softback
                    </div>
                  </div>

                  <div className="grid grid-cols-3">
                    <div className="px-4 py-2 bg-gray-100 border-b border-gray-200 font-medium">
                      Compatibility
                    </div>
                    <div className="col-span-2 px-4 py-2 border-b border-gray-200">
                      Kindle, Digibook, Verso, RK Book, Pocketbook 306
                    </div>
                  </div>

                  <div className="grid grid-cols-3">
                    <div className="px-4 py-2 bg-gray-100 border-b border-gray-200 font-medium">
                      Published By
                    </div>
                    <div className="col-span-2 px-4 py-2 border-b border-gray-200">
                      <a href="#." className=" text-sky-600 hover:underline">
                        Quivertree Publications
                      </a>
                    </div>
                  </div>

                  <div className="grid grid-cols-3">
                    <div className="px-4 py-2 bg-gray-100 border-b border-gray-200 font-medium">
                      Published Date
                    </div>
                    <div className="col-span-2 px-4 py-2 border-b border-gray-200">
                      2016-09-16
                    </div>
                  </div>

                  <div className="grid grid-cols-3">
                    <div className="px-4 py-2 bg-gray-100 border-b border-gray-200 font-medium">
                      Pages
                    </div>
                    <div className="col-span-2 px-4 py-2 border-b border-gray-200">
                      320
                    </div>
                  </div>
                </div>
              </section>

              <section>
                <h3 className="w-full text-2xl font-semibold font-merriweather mb-3">
                  More for you...
                </h3>
                <div className="flex w-full items-stretch space-x-2">
                  {productLoading ? (
                    <div className="relative p-10 w-full">
                      <AppLoader sectionLoading={productLoading} />
                    </div>
                  ) : (
                    <WideSliderCard
                      type={GLOBAL.SLIDER.CONSIDERATIONS}
                      sliderSettings={{
                        slidesToShow: 4,
                        dots: false,
                        autoplay: false,
                      }}
                    />
                  )}
                </div>
              </section>

              <section
                ref={reviewsRef}
                className="p-6 border border-gray-200 shadow bg-white"
              >
                {productLoading ? (
                  <div className="relative p-10 w-full">
                    <AppLoader sectionLoading={productLoading} />
                  </div>
                ) : (
                  <>
                    <div className="flex bg-gray-100 p-5 mb-4">
                      <div className="text-center basis-1/2">
                        <div className="text-4xl font-semibold">
                          {dataProduct.rating} out of 5
                        </div>
                        <div>
                          <ReviewStars />
                        </div>

                        <div className="font-bold my-3">97 Reviews</div>
                        <div>
                          <BtnWriteReview />
                        </div>
                      </div>
                      <div className="basis-1/2 text-sm">
                        <ReviewStarsCategory
                          noOfReviews={15}
                          rate={5}
                          rateAverage={'45.5%'}
                        />
                        <ReviewStarsCategory
                          noOfReviews={1}
                          rate={4}
                          rateAverage={'37.5%'}
                        />
                        <ReviewStarsCategory
                          noOfReviews={0}
                          rate={3}
                          rateAverage={'0%'}
                        />
                        <ReviewStarsCategory
                          noOfReviews={0}
                          rate={2}
                          rateAverage={'0%'}
                        />
                        <ReviewStarsCategory
                          noOfReviews={3}
                          rate={1}
                          rateAverage={'75.5%'}
                        />
                      </div>
                    </div>
                    <h3 className="w-full text-2xl font-semibold font-merriweather">
                      Customer Reviews on {dataProduct.name}
                    </h3>
                    <div className="my-3">
                      <span className="font-bold ">
                        Showing 1 to 10 of 97 Reviews
                      </span>{' '}
                      <span className="rounded-full text-sm bg-green-700 text-white px-2 py-1">
                        5 <MyIconStar size="small" color="#ffffff" /> reviews
                      </span>
                      <span className="text-sm text-sky-600 hover:cursor-pointer px-3 py-2 hover:underline">
                        Show All
                      </span>
                    </div>
                    <hr className="mt-3 bg-gray-200" />
                    <div className="divide-y">
                      <ReviewCard />
                      <ReviewCard />
                      <ReviewCard />
                    </div>
                    <hr className="mb-6 bg-gray-200" />
                    <Pagination />
                  </>
                )}
              </section>
            </div>
            <div className="w-3/12 mb-7">
              <div className="p-6 border relative border-gray-200 shadow bg-white">
                {productLoading ? (
                  <div className="relative p-10 w-full">
                    <AppLoader sectionLoading={productLoading} />
                  </div>
                ) : (
                  <>
                    <span className="float-right">
                      <ProductSale
                        productStock={dataProduct.stock}
                        productDiscountPerc={dataProduct.discount_percentage}
                        position="relative"
                      />
                      <ProductOutOfStock
                        productStock={dataProduct.stock}
                        position="relative"
                      />
                    </span>

                    <div className="text-xl">
                      <ProductPrice
                        productPrice={dataProduct.price}
                        productDiscountPerc={dataProduct.discount_percentage}
                        productDiscountPrice={dataProduct.discount_price}
                        size="large"
                      />
                    </div>
                    <div className="text-xs font-medium my-2">
                      ORDER 2+ AND GET FREE DELIVERY
                    </div>

                    <BtnAddToCart product={dataProduct} />
                    <BtnAddToWishList />

                    {cartItems.length ? (
                      <div
                        onClick={() => {
                          navigate(PORTAL.CART)
                        }}
                        className="w-full text-center font-medium text-sm border-1 border-gray-100 rounded-sm inline-block hover:cursor-pointer p-2 my-1 whitespace-nowrap"
                      >
                        Go to Cart{' '}
                        <ChevronRightIcon
                          className={`-ml-1 -mt-0.5 h-5 w-5 inline`}
                          aria-hidden="true"
                        />
                      </div>
                    ) : (
                      <></>
                    )}

                    <hr className="my-3" />
                    <div className="flex">
                      <img
                        alt="Product QR Code"
                        src={QRCodeExample}
                        className="w-auto h-16"
                      />
                      <p className="pl-2 leading-tight text-sm">
                        <span className="font-bold">Open on mobile</span>
                        <br />
                        <span>
                          Scan the QR code using your mobile phone camera to
                          open this page on your mobile phone.
                        </span>
                      </p>
                    </div>

                    <div className="text-xs font-medium mt-3 text-gray-400">
                      PRODUCT CODE:
                      <br />
                      HOBOWLFGRIDBUNDLE
                    </div>
                  </>
                )}
              </div>

              <ul className="w-full p-4 list-disc pl-6">
                <li>
                  <a href="#." className="cursor-pointer hover:underline">
                    30 Day Exchanges
                  </a>
                </li>
                <li>
                  <a href="#." className="cursor-pointer hover:underline">
                    Delivery Information
                  </a>
                </li>
                <li>
                  <a href="#." className="cursor-pointer hover:underline">
                    Returns Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
