export default function ProductSale({
  productStock,
  productDiscountPerc,
  position = null,
}) {
  return (
    productStock !== 0 &&
    productDiscountPerc > 0 && (
      <span
        className={`${
          position === `absolute`
            ? `absolute top-1.5 left-0 z-40 rounded-tr rounded-br`
            : `relative rounded`
        } ${`bg-red-700 text-white text-[11px] font-medium uppercase py-1 px-2 mb-2 whitespace-nowrap`}`}
      >
        {productDiscountPerc}% Off
      </span>
    )
  )
}
