import { useState } from 'react'
import React from 'react'
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom'
import { AppLoaderContext } from 'contexts/loader'
import { PORTAL } from 'routes/portal'
import { HelmetProvider } from 'react-helmet-async'
import MainLayout from 'layouts/main'
import HomePage from 'pages/home'
import ProductPage from 'pages/product'
import SupplierPage from 'pages/supplier'
import CartPage from 'pages/cart'
import NotFound from 'pages/404'
import CartContextProvider from './contexts/CartContext'
import ProductsContextProvider from './contexts/ProductsContext'

export default function App() {
  const [loading, setLoading] = useState(false)

  return (
    <>
      <HelmetProvider>
        <AppLoaderContext.Provider value={{ loading, setLoading }}>
          <ProductsContextProvider>
            <CartContextProvider>
              <BrowserRouter>
                <Routes>
                  {/* <PageView /><GoogleAnalytics /> */}
                  <Route path={PORTAL.HOME} element={<MainLayout />}>
                    <Route index element={<HomePage />} />
                    <Route
                      path={PORTAL.PRODUCT_PAGE}
                      element={<ProductPage />}
                    />
                    <Route exact path={PORTAL.CART} element={<CartPage />} />
                    <Route
                      path={PORTAL.SUPPLIER_PAGE}
                      element={<SupplierPage />}
                    />
                    <Route path={PORTAL.NOT_FOUND} element={<NotFound />} />
                    <Route path="*" element={<NotFound />} />
                  </Route>
                </Routes>
              </BrowserRouter>
            </CartContextProvider>
          </ProductsContextProvider>
        </AppLoaderContext.Provider>
      </HelmetProvider>
    </>
  )
}
